import React from 'react';
import { MainLayout } from '../components/Layout';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

export default () => {
  return (
    <MainLayout siteTitle="Erreur 404">
      <Helmet>
        <meta
          http-equiv="refresh"
          content={`5;URL=${process.env.GATSBY_WEBSITE_URL}`}
        />
      </Helmet>
      <section className="mt-10 mb-20">
        <div className="row pl-2-md pr-2-md">
          <div className="col-12 pt-10 pb-20">
            <div className="roboto bold font-size-xxl text-align-center pb-5">
              Oupsy...
            </div>
            <div className="font-size-md text-align-center">
              On dirait bien que cette page n'existe pas{' '}
              <span role="img" aria-label="Oups" className="font-size-xl">
                &#128561;
              </span>
            </div>
            <div className="mt-10 text-align-center">
              <Link to={'/contact/'} className="btn secondary mr-2">
                Nous contacter
              </Link>{' '}
              <Link to={'/'} className="ml-2 btn">
                Page d'accueil
              </Link>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  );
};
